<template>
  <div v-if="bankAccount" class="grid-x grid-padding-x">
      <div class="cell large-6 medium-12 small-12">
        <md-field :class="getValidationClass('accountOwner')">
          <label for="accountOwner">Kontoinhaber</label>
          <md-input name="accountOwner" id="accountOwner" autocomplete="off"
                    v-model="bankAccount.accountOwner" :disabled="sending"/>
          <span class="md-error" v-if="!$v.bankAccount.accountOwner.required">Kontoinhaber ist notwendig</span>
        </md-field>
      </div>
      <div class="cell large-6 medium-6 small-12">
        <md-field>
          <label for="bank-name">Name der Bank</label>
          <md-input name="bank-name" id="bank-name" autocomplete="off"
                    v-model="bankAccount.bankname" :disabled="sending"/>
        </md-field>
      </div>
      <div class="cell large-6 medium-6 small-12">
        <md-field :class="getValidationClass('iban')">
          <label for="iban">IBAN</label>
          <md-input name="iban" id="iban" autocomplete="off"
                    v-model="bankAccount.iban" :disabled="sending"/>
          <span class="md-error" v-if="ibanInvalid">IBAN ist ungültig</span>
        </md-field>
      </div>
      <div class="cell large-6 medium-6 small-12">
        <md-field :class="getValidationClass('bic')">
          <label for="bic">BIC</label>
          <md-input name="bic" id="bic" autocomplete="off"
                    v-model="bankAccount.bic" :disabled="sending"/>
          <span class="md-error" v-if="!$v.bankAccount.bic.required">BIC ist notwendig</span>
        </md-field>
      </div>
      <div v-if="!mandateReferenceDisabled" class="cell large-6 medium-6 small-12">
        <md-field>
          <label for="mandateReference">Referenz-Mandat</label>
          <md-input name="mandateReference" id="mandateReference" autocomplete="off"
                    v-model="bankAccount.mandateReference" :disabled="sending"/>
        </md-field>
      </div>
      <div v-if="!mandateReferenceDisabled && paymentMethod === 'SEPA'" class="cell large-6 medium-6 small-12">
        <div class="md-layout">
          <div class="md-layout-item" style="padding-right: 15px;">
            <md-datepicker id="mandate-granting-date-dp" :md-debounce="10000" :md-open-on-focus="false" v-model="dateOfMandateGranting" md-immediately :disabled="sending">
              <label>Datum der Mandatserteilung</label>
            </md-datepicker>
          </div>
        </div>
      </div>
      <div v-if="firstIsRecurrent" class="cell large-6 medium-6 small-12">
        <div class="md-layout">
          <md-checkbox class="md-primary" v-model="bankAccount.firstIsRecurrent">
            <span style="font-size: 1rem">Erste Abbuchung als Folgelastschrift</span>
            <md-tooltip>Die erste Abbuchung wird als Folgelastschrift ausgeführt.</md-tooltip>
          </md-checkbox>
        </div>
      </div>
    </div>
</template>

<script>

  import jQuery from 'jquery';
  import Vue from 'vue';
  import {validationMixin} from 'vuelidate';
  import IBAN from 'iban';
  import {
    required,
  } from 'vuelidate/lib/validators';

  export default {
    name: 'BankAccountForm',
    mixins: [validationMixin],

    props: {
      bankAccount: {
        required: true
      },
      paymentMethod: {
        required: true
      },
      sending: {
        required: true
      },
      mandateReferenceDisabled: {
        required: true,
      },
      firstIsRecurrent: {
        required: false
      },
    },

    created() {
      this.formatDateOfMandateGranting();
      this.initDatePickerSyncHandler(true);
    },

    beforeDestroy() {
      this.initDatePickerSyncHandler(false);
    },

    data: () => ({
      invalid: true,
      ibanInvalid: true,
      dateOfMandateGranting: null,
    }),

    validations: {
      bankAccount: {
        accountOwner: {
          required,
        },
        bic: {
          required,
        },
        iban: {
          required,
        },
      },
    },

    methods: {
      initDatePickerSyncHandler(init) {
        if (init) {
          setTimeout(() => {
            jQuery('#mandate-granting-date-dp').children('input').bind('blur', () => {
              this.dateOfMandateGranting = jQuery('#mandate-granting-date-dp').children('input').val();
              this.writeBackDates();
            })
          }, 500);
        } else {
          jQuery('#mandate-granting-date-dp').children('input').unbind('blur');
        }
      },
      
      formatDateOfMandateGranting() {
        if (this.dateOfMandateGranting === null) {
          if (this.bankAccount && this.bankAccount.dateOfMandateGranting) {
            this.dateOfMandateGranting = Vue.moment(this.bankAccount.dateOfMandateGranting).format('DD.MM.YYYY');
          }
        }
      },

      getValidationClass(fieldName) {
        if (this.sending) {
          return '';
        }

        let field = this.$v.bankAccount[fieldName];

        if (fieldName === 'iban' && field) {
          this.ibanInvalid = !IBAN.isValid(this.bankAccount.iban);
          return { 'md-invalid': this.ibanInvalid && field.$dirty }
        }

        if (field) {
          return {
            'md-invalid': field.$invalid && field.$dirty
          }
        }
      },

      writeBackDates() {
        if (this.paymentMethod === 'SEPA') {
          if (this.dateOfMandateGranting) {
            this.bankAccount.dateOfMandateGranting = Vue.moment(this.dateOfMandateGranting, 'DD.MM.YYYY').format('YYYY-MM-DD');
          } else {
            this.bankAccount.dateOfMandateGranting = null;
          }
        } else {
          // Dummy Date
          this.bankAccount.dateOfMandateGranting = Vue.moment('01-01-1970', 'DD-MM-YYYY');
        }
      },

      validateBankAccount() {
        this.$v.$touch();
        this.writeBackDates();
        this.invalid = this.$v.$invalid;

        if (!IBAN.isValid(this.bankAccount.iban)) {
          this.invalid = true;
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
</style>